<template>
  <a
    :href="link"
    class="lang-switcher-link flex items-center px-2 py-1 text-primary transition hover:bg-primary hover:text-white"
    data-testid="lang-switcher-link"
  >
    <Icon
      :name="`circle-flags:${isoCode}`"
      class="mr-2 block h-5 w-5 min-w-[20px] rounded-full border border-primary bg-cover bg-center"
    />

    <span>{{ langsNames[locale] }}</span>
  </a>
</template>

<script lang="ts" setup>
import { getIsoLang, langsNames } from '@autobid/ui/utils/langUtils'

interface Props {
  locale: string
  link: string
}

const props = defineProps<Props>()

const isoCode = getIsoLang(props.locale)
</script>
